import { mapGetters, mapState, mapActions } from "vuex"
import enterpriseApi from "@/api/enterprise.api";
import { ColvenTheme } from '@/constants/constants';
import i18n from '@/i18n'
import { enterpriseService } from "@/business/enterprise.service";
export default {
    name: 'EnterpriseSelector',
    data() {
        return {
            validForm: false,
            submitted: false,
            enterprises: [],
            selectedEnterprise: null,
            showGoogleAutenticator: false,
            validationCode: null,
            haveGoogleAutenticatorSecret:null,
            rules: {
                required: value => !!value || `${i18n.t('required')}`
            },
        }
    },
    async mounted() {
        await this.getEnterprises();
        await this.getGoogleAutenticatorData();
    },
    beforeDestroy () {
        // Carga los temas (si se logea un usuario diferente)
        if (this.getThemes) {
          this.$vuetify.theme.themes.light = this.getThemes.light
          this.$vuetify.theme.themes.dark = this.getThemes.dark
        } else {
          this.$vuetify.theme.themes.light = ColvenTheme.light
          this.$vuetify.theme.themes.dark = ColvenTheme.dark
        }
        this.$vuetify.theme.dark = this.getDark
    },
    computed: {
        ...mapGetters({
            'getLogo': 'user/getLogo',
			'getDark': 'app/getDark',
			'getThemes': 'user/getThemes'
        }),
        logoSource() {
            if (this.selectedEnterprise) {
                if (this.selectedEnterprise.logo) {
                  return this.selectedEnterprise.logo
                } else {
                  return require('../../assets/logo.png')
                }
              } else if (this.getLoginLogo) {
                  return 'data:image/png;base64,' + this.getLoginLogo
              } else {
                  return require('../../assets/logo.png')
              }
        },
        /**
        * src de la imagen de fondo
        */
        backgroundImageSource() {
            if (this.getLoginBackgroundImage) {
                return 'data:image/png;base64,' + this.getLoginBackgroundImage
            } else {
                return require('../../assets/login-background.jpg')
            }
        },
    },
    methods: {
        ...mapActions('user', {
            setLogo: 'setLogo',
            setLoginLogo: 'setLoginLogo',
            setLoginBackgroundImage: 'setLoginBackgroundImage',
            setThemes: 'setThemes',
            setEnterpriseId: 'setEnterpriseId'
        }),
        ...mapGetters('user', [
            'getThemes'
          ]),
        ...mapState('app'),
        async getEnterprises() {
            this.enterprises = (await enterpriseApi.getAllWithLogo()).data
            this.enterprises.forEach(e => {
                e.logo = e.logo ? 'data:image/png;base64,' + e.logo : undefined
            })
            this.selectedEnterprise = this.enterprises[0]
        },
        async getGoogleAutenticatorData() {
            this.haveGoogleAutenticatorSecret = localStorage.getItem('haveGoogleAutenticatorSecret')
            if(this.haveGoogleAutenticatorSecret){
                this.showGoogleAutenticator = true;
            }
        },
        async submit() {          
            await enterpriseService.setEnterpriseAndSecondFactor(this.selectedEnterprise.id, this.validationCode)
            this.$router.push("/home");
           
        }
    },
}